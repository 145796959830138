import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {graphql, Link, useStaticQuery} from "gatsby";
import { Trans } from "@lingui/macro";
import "./OrderForm.css"
import axios from "axios";
import {
  FormGroup,
  Alert
} from "reactstrap";
import Reaptcha from "reaptcha";
import Loader from "react-loader-spinner";
import Header from "../layout/header";
import { useDetectAdBlock } from "adblock-detect-react";
import Img from "gatsby-image"


const OrderForm = ({locale, cart}) => {
    const [summaryData, setSummaryData] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [visible, setVisible] = useState(false);
    const [sameShipping, setSameShipping] = useState(true);
    const [shipping, setShipping] = useState("kurier");
    const [paymentSum, setPaymentSum] = useState(4);
    const [payment, setPayment] = useState("Kuriér - platba prevodom");
    const [mailchimp, setMailchimp] = useState(false);
    const onDismiss = () => setVisible(false);
    const [recaptcha, setRecaptcha] = useState("");
    const [showValid, setShowValid] = useState(false);
    const { register, handleSubmit, errors, reset } =  useForm();
    const [show, setShow] = useState(false);
    const onVerify = recaptchaResponse => {
       setRecaptcha(recaptchaResponse);
    };
    const adBlockDetected = useDetectAdBlock();

    const query = useStaticQuery(graphql`
      query {
          file(relativePath: {in: "assets/barion-card-strip-intl_200px.png"}) {
              childImageSharp {
                  fixed(height: 200) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
    `);


    React.useEffect(() => {
      if (adBlockDetected) {
        window.alert("ad block detected");
      }
    }, []);

    const onSubmit = data => {
        setShow(true);
       
        let sameShippingAddress = document.getElementById('sameShippingAddress').checked;
        let mailchimpConfirmation = document.getElementById('mailchimp').checked;
       

        let formData = {
            firstName: document.getElementById('firstName').value,
            lastName: document.getElementById('lastName').value,
            companyId: document.getElementById('companyId').value,
            taxId: document.getElementById('taxId').value,
            vatId: document.getElementById('vatId').value,
            street: document.getElementById('street').value,
            city: document.getElementById('city').value,
            zip: document.getElementById('zip').value,
            tel: document.getElementById('tel').value,
            email: document.getElementById('email').value,
            shippingSameAddress: sameShippingAddress,
            shippingName: sameShippingAddress? document.getElementById('firstName').value + " " + document.getElementById('lastName').value : document.getElementById('shippingName').value,
            shippingStreet: sameShippingAddress? document.getElementById('street').value : document.getElementById('shippingStreet').value,
            shippingCity: sameShippingAddress? document.getElementById('city').value : document.getElementById('shippingCity').value,
            shippingZip: sameShippingAddress? document.getElementById('zip').value : document.getElementById('shippingZip').value,
            shippingTel: sameShippingAddress? document.getElementById('tel').value : document.getElementById('shippingTel').value,
            note: document.getElementById('note').value,
            items: JSON.parse(document.getElementById('items').value)
        }
        let shippingItem = {
            id: 100,
            amount: 1,
            description: payment,
            price1NoVat: paymentSum,
            price1Vat: paymentSum*1.2,
            unit: "ks",
            vat: 20,
        }
        formData.items.push(shippingItem)
        setSummaryData(formData);


        if (payment === "Kuriér - platba prevodom" || payment === "Kuriér - Barion kartou" || payment === "Kuriér - dobierka"){
          let termoboxItem = {
            id: 101,
            amount: 1,
            description: "Termobox balenie",
            price1NoVat: 4,
            price1Vat: 4.80,
            unit: "ks",
            vat: 20,
        }
        formData.items.push(termoboxItem)
        }

        
        let subscribeData = {
            firstName: document.getElementById('firstName').value,
            lastName: document.getElementById('lastName').value,
            email: document.getElementById('email').value,
        }

        

        
        if(mailchimpConfirmation === true){
          
            subscribeData.token = recaptcha;

            axios({
                method: "POST",
                url: process.env.API_SUBSCRIBE_URL,
                data: subscribeData,
                headers :{
                    'Content-Type': 'application/json'
                }
            }).then((response)=>{
                setMailchimp(true);
            }).catch((error)=>{
                console.log(error);
            })
        }

        if(recaptcha.length < 1){
            setShowValid(true);
            setShow(false);
        }
        else{
              setShowValid(false);
              formData.token = recaptcha;
              axios({
                  method: "POST",
                  url: process.env.API_ORDER_URL,
                  data: formData,
                  headers :{
                      'Content-Type': 'application/json'
                  }
              }).then((response)=>{
                  
                  setIsSuccess(true);
                  setShow(false);
                  setRecaptcha("");
                  setVisible(false);
                  cart.clear();
                  reset();
              })
              .catch((error)=>{
                  console.log(error);
                  setVisible(true);
              })
        }
    }

    function checkboxClick(e) {
        setSameShipping(e.target.checked);
    }

    function shippingChange(e) {
        setShipping(e);
        setPayment("Doprava a platba");
        setPaymentSum(0);
    }

    function successStatus() {
      setIsSuccess(false);
      setSummaryData(null);
    }

    function paymentChange(e) {
      setPayment(e);
      setPaymentSum(
        Number(
        {
          // 'Osobný odber - platba prevodom': 0,
          // 'Osobný odber - Barion kartou': 0,
          // 'Osobný odber - Hotovosť': 0,
          // 'Vlastný rozvoz - platba-prevodom': 3.6,
          // 'Vlastný rozvoz - Barion kartou': 3.6,
          // 'Vlastný rozvoz - Hotovosť': 3.6,
          'Kuriér - platba prevodom': 4,
          'Kuriér - Barion kartou': 4,
          'Kuriér - dobierka': 4.92,
          }[e]
        )
      )
        }
    function checkboxMailchimpClick(e) {
        setMailchimp(e.target.checked);
    }

    return (
        <div>
        <Header locale={locale} />
        <div className="MainContent">
            <section>
                <div className="container">
                    <div className="OrderForm">
        {isSuccess ?
          <div className="success-form-submit">
              <h3>Ďakujeme za vašu objednávku</h3>
              <p>Vážime si, že ste si u nás objednali. <br/>
              Vašu objednávku skontrolujeme a do 24 hodín Vám zašleme potvrdenie s platobnými údajmi.</p>
              {summaryData != null ?(
                <div>


<div className="ContactInfo">
<div className="c50p">
<h3><Trans>Fakturačné údaje</Trans></h3>
          <p>
              <strong><Trans>Meno/Firma</Trans></strong>: {summaryData.firstName} <br/>
              <strong><Trans>Priezvisko</Trans>: </strong>{summaryData.lastName}<br/>
              <strong><Trans>IČO:</Trans></strong> {summaryData.companyId}<br/>
              <strong><Trans>DIČ</Trans>: </strong>{summaryData.taxId}<br/>
              <strong><Trans>DIČ DPH.</Trans>: </strong>{summaryData.vatId} <br/>
              <strong><Trans>Ulica č.</Trans>: </strong>{summaryData.street}<br/>
              <strong><Trans>Mesto</Trans>: </strong>{summaryData.city} <br/>
              <strong><Trans>PSČ</Trans>: </strong>{summaryData.zip} <br/>
              <strong><Trans>Tel.</Trans></strong>: {summaryData.tel} <br/>
              <strong><Trans>E-mail</Trans>: </strong>{summaryData.email}<br/>
              <strong><Trans>Poznámka:</Trans></strong>: {summaryData.note}<br/>
          </p>
</div>
<div className="c50p">
<h3><Trans>Adresa doručenia</Trans></h3>
<p>
          <strong><Trans>Celé meno</Trans>:</strong> {summaryData.shippingName}<br/>
              <strong><Trans>Ulica č.</Trans>:</strong> {summaryData.shippingStreet} <br/>
              <strong><Trans>Mesto</Trans>:</strong> {summaryData.shippingCity}<br/>
              <strong><Trans>PSČ</Trans>:</strong> {summaryData.shippingZip} <br/>
              <strong><Trans>Telefón</Trans>:</strong>  {summaryData.shippingTel}<br/>
              </p>
</div>
<div className="cleaner">&nbsp;</div>
          

          

         

      </div>

      <h3><Trans>Objednávka</Trans></h3>

              <table>
                  <thead>
                  <tr>
                      <th><Trans>Tovar</Trans></th>
                      <th><Trans>Množstvo</Trans></th>
                      <th><Trans>Jendotková cena</Trans></th>
                      <th><Trans>Cena spolu</Trans></th>
                  </tr>
                  </thead>
                  <tfoot>
                  <tr>
                      <th colSpan="3"><Trans>Spolu</Trans></th>
                      <th>
                          {Math.round((cart.getTotal(true) + paymentSum)*120)/100} &euro; <Trans>s DPH</Trans><br/>
                          {Math.round((cart.getTotal(false) + paymentSum)*100)/100} &euro; <Trans>bez DPH</Trans>
                      </th>
                  </tr>
                  </tfoot>
                  <tbody>
                  {summaryData.items.map( ( {description, price1NoVat, amount, price1Vat, unit} ) => (


                          <tr key={description}>
                              <td>{description}</td>
                              <td>{amount} {unit}</td>
                              <td>
                                  {Math.round(price1Vat*100)/100} &euro; <Trans>s DPH</Trans><br/>
                                  {Math.round(price1NoVat*100)/100} &euro; <Trans>bez DPH</Trans>
                              </td>
                              <td>
                                  {Math.round(amount * price1Vat*100)/100} &euro; <Trans>s DPH</Trans><br/>
                                  {Math.round(amount * price1NoVat*100)/100} &euro; <Trans>bez DPH</Trans>
                              </td>
                          </tr>

                  ))}
                  </tbody>
              </table>
              </div>
                ): ""}
              <button onClick={successStatus} className="button-basic primary-invert">Vytvoriť novú objednávku</button>
          </div>
           :

          <form id="orderForm" onSubmit={handleSubmit(onSubmit)}>
                <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
                   Posím, skúste znova, odosielanie zlyhalo.
                </Alert>
                <div className="c50p">
                  <h6><Trans>Fakturačné údaje</Trans></h6>
                  <div className="form-row">
                      <label className="required"><Trans>Meno</Trans>/<Trans>Firma</Trans></label>
                      <input type="text" id="firstName" name="name" ref={register({required: true, maxLength: 80})}/>
                      {errors.name && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                  </div>
                  <div className="form-row">
                      <label ><Trans>Priezvisko</Trans></label>
                      <input type="text" id="lastName" name="name" ref={register({maxLength: 80})}/>
                  </div>
                  <div className="form-row">
                      <label><Trans>IČO</Trans></label><input type="text" id="companyId" name="companyId"/>
                  </div>
                  <div className="form-row">
                      <label><Trans>DIČ</Trans></label><input type="text" id="taxId" name="taxId"/>
                  </div>
                  <div className="form-row">
                      <label><Trans>IČ DPH</Trans></label><input type="text" id="vatId" name="vatId"/>
                  </div>
                  <div className="form-row">
                      <label className="required"><Trans>Ulica č.</Trans></label><input type="text" id="street" name="street" ref={register({required: true, maxLength: 255})}/>
                      {errors.street && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                  </div>
                  <div className="form-row">
                      <label className="required"><Trans>Mesto</Trans></label><input type="text" id="city" name="city" ref={register({required: true, maxLength: 255})}/>
                      {errors.city && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                  </div>
                  <div className="form-row">
                      <label className="required"><Trans>PSČ</Trans></label><input type="text" id="zip" name="zip" ref={register({required: true, maxLength: 5})}/>
                      {errors.zip && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                  </div>
                  <div className="form-row">
                      <label className="required"><Trans>Telefón</Trans></label><input type="text" id="tel" name="tel" ref={register({required: true, maxLength: 255})}/>
                      {errors.tel && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                  </div>
                  <div className="form-row">
                      <label className="required"><Trans>Email</Trans></label><input type="text" id="email" name="email" ref={register({required: true, maxLength: 255, pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Nesprávny tvar emailovej adresy"
                      }
                  })}/>
                      {errors.email && <div className="requirements">{errors.email.message || <Trans>Povinný údaj</Trans>}</div>}
                  </div>
              </div>
              <div className="c50p">
                  <h6><Trans>Adresa doručenia</Trans></h6>
                  <div className="form-row">
                      <input type="checkbox"name="sameShippingAddress" id="sameShippingAddress" onClick={checkboxClick} defaultChecked={sameShipping} ref={register}/> <Trans>Adresa doručenia je rovnaká ako fakturačná adresa</Trans>
                  </div>
                  <div id="shipping" style={{display: 'block'}}>
                      <div className="form-row">
                          <label className="required"><Trans>Celé meno</Trans></label><input type="text" id="shippingName" name="shippingName" disabled={sameShipping} maxLength="255" ref={register({required: !sameShipping})}/>
                          {errors.shippingName && !sameShipping && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                      </div>
                      <div className="form-row">
                          <label className="required"><Trans>Ulica č.</Trans></label><input type="text" id="shippingStreet" name="shippingStreet" disabled={sameShipping} maxLength="255" ref={register({required: !sameShipping})}/>
                          {errors.shippingStreet && !sameShipping && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                      </div>
                      <div className="form-row">
                          <label className="required"><Trans>Mesto</Trans></label><input type="text" id="shippingCity" name="shippingCity"disabled={sameShipping} maxLength="255" ref={register({required: !sameShipping})}/>
                          {errors.shippingCity && !sameShipping && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                      </div>
                      <div className="form-row">
                          <label className="required"><Trans>PSČ</Trans></label><input type="text" id="shippingZip" name="shippingZip" disabled={sameShipping} maxLength="5" ref={register({required: !sameShipping})}/>
                          {errors.shippingZip && !sameShipping &&  <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                      </div>
                      <div className="form-row">
                          <label className="required"><Trans>Telefón</Trans></label><input type="text" id="shippingTel" name="shippingTel" disabled={sameShipping} maxLength="255" ref={register({required: !sameShipping})}/>
                          {errors.shippingTel && !sameShipping && <div className="requirements"><Trans>Povinný údaj</Trans></div>}
                      </div>
                  </div>
                  <div className="form-row">
                      <h6><Trans>Poznámka</Trans></h6>
                      <textarea id="note"></textarea>
                  </div>
              </div>

              <div className="form-row c100p mt-4" style={{display: 'block'}}>
                  <div className="form-row c50p">
              <h6><Trans>Doprava</Trans></h6>
              {/* <div className="radio">
          <label className="radio-label">
            <input
              id="osobny-odber"
              name="shipping"
              type="radio"
              value="osobny-odber"
              checked={shipping === "osobny-odber"}
              onChange={(e)=>{shippingChange(e.target.value)}}
            />
            Osobný odber
          </label>
        </div>
        <div className="radio">
          <label className="radio-label">
            <input
              name="shipping"
              type="radio"
              value="vlastny-rozvoz"
              checked={shipping === "vlastny-rozvoz"}
              onChange={(e)=>{shippingChange(e.target.value)}}
            />
             Vlastný rozvoz
          </label> */}
        {/* </div> */}
        <div className="radio">
          <label className="radio-label">
            <input
              name="shipping"
              type="radio"
              value="kurier"
              checked={shipping === "kurier"}
              onChange={(e)=>{shippingChange(e.target.value)}}
            />
            Kuriér
          </label>
        </div>

        </div>


        <div className="form-row c50p">

              <h6><Trans>Spôsob platby</Trans></h6>
            <div>
           {/* {shipping === "osobny-odber" && <div>

           <div className="radio">
          <label className="radio-label">
            <input
              name="osobny-odber"
              type="radio"
              value="Osobný odber - platba prevodom"
              checked={payment === "Osobný odber - platba prevodom"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
            Platba prevodom (0.00 €)
          </label>
        </div>
        <div className="radio">
          <label className="radio-label">
            <input
              name="osobny-odber"
              type="radio"
              value="Osobný odber - Barion kartou"
              checked={payment === "Osobný odber - Barion kartou"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
             Barion - kartou (0.00 €)
          </label>
        </div>
        <div className="radio">
          <label className="radio-label">
            <input
              name="osobny-odber"
              type="radio"
              value="Osobný odber - Hotovosť"
              checked={payment === "Osobný odber - Hotovosť"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
            Hotovosť (prípadne platba kartou) (0.00 €)
          </label>
        </div>
           </div>
           }

            {shipping === "vlastny-rozvoz" && <div>

           <div className="radio">
          <label className="radio-label">
            <input
              name="vlastny-rozvoz"
              type="radio"
              value="Vlastný rozvoz - platba-prevodom"
              checked={payment === "Vlastný rozvoz - platba-prevodom"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
            Platba prevodom (3.60 €)
          </label>
        </div>
        <div className="radio">
          <label className="radio-label">
            <input
              name="vlastny-rozvoz"
              type="radio"
              value="Vlastný rozvoz - Barion kartou"
              checked={payment === "Vlastný rozvoz - Barion kartou"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
             Barion - kartou (3.60 €)
          </label>
        </div>
        <div className="radio">
          <label className="radio-label">
            <input
              name="vlastny-rozvoz"
              type="radio"
              value="Vlastný rozvoz - Hotovosť"
              checked={payment === "Vlastný rozvoz - Hotovosť"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
            Hotovosť (prípadne platba kartou) (3.60 €)
          </label>
        </div>
           </div>
           } */}

        {shipping === "kurier" && <div>

           <div className="radio">
          <label className="radio-label">
            <input
              name="kurier"
              type="radio"
              value="Kuriér - platba prevodom"
              checked={payment === "Kuriér - platba prevodom"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
            Platba prevodom (4.80 €)
          </label>
        </div>
        <div className="radio">
          <label className="radio-label">
            <input
              name="kurier"
              type="radio"
              value="Kuriér - Barion kartou"
              checked={payment === "Kuriér - Barion kartou"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
             Barion - kartou (4.80 €)
             <Img fixed={query.file.childImageSharp.fixed} style={{top: '5px', left: '10px'}}/>
          </label>
        </div>
        <div className="radio">
          <label className="radio-label">
            <input
              name="kurier"
              type="radio"
              value="Kuriér - dobierka"
              checked={payment === "Kuriér - dobierka"}
              onChange={(e)=>{paymentChange(e.target.value)}}
            />
            Dobierka (5.90 €)
          </label>
        </div>
        <span>4.80 €  -príplatok za balenie počas dní, ak denná teplota presiahne 7 stupňov.
              Cena za balenie bude zrušená pri potvrdení objednávky, ak balenie nebude potrebné riešiť termoboxom, prípadne podla povahy objednaného tovaru.</span>
                   </div>
           }



    </div>

        </div>


              </div>
              <div className="cleaner">&nbsp;</div>
              <h6><Trans>Obsah objednávky</Trans></h6>
              {show === true ?<div className="overlay-loader mx-auto">
                <Loader
                  type="ThreeDots"
                  color="#ebd187"
                  height={100}
                  width={100}
                  timeout={0} //3 secs
                  className="overlay-loader-svg"
                />
                <h3>Vaša objednávka sa spracováva</h3>
              </div> : ""}
              <input type="hidden" id="items" value={JSON.stringify(cart.toArray())}/>
              <table>
                  <thead>
                  <tr>
                      <th><Trans>Tovar</Trans></th>
                      <th><Trans>Množstvo</Trans></th>
                      <th><Trans>Jendotková cena</Trans></th>
                      <th><Trans>Cena spolu</Trans></th>
                  </tr>
                  </thead>
                  <tfoot>
                  <tr>
                      <th colSpan="3"><Trans>Spolu</Trans></th>
                      <th>
                          {Math.round((cart.getTotal(true) + (paymentSum*1.2) + 4.8)*100)/100} &euro; <Trans>s DPH</Trans><br/>
                          {Math.round((cart.getTotal(false) + paymentSum + 4)*100)/100} &euro; <Trans>bez DPH</Trans>
                      </th>
                  </tr>
                  </tfoot>
                  <tbody>
                  {cart.size() === 0 ?
                      (<tr><td colSpan="5"><Trans>Nákupný košík je prázdny</Trans></td></tr>) :
                      (cart.toArray().map((node)=>
                          <tr key={"orderItem"+node.id}>
                              <td>{node.description}</td>
                              <td>{node.amount} {node.unit}</td>
                              <td>
                                  {Math.round(node.price1Vat*100)/100} &euro; <Trans>s DPH</Trans><br/>
                                  {Math.round(node.price1NoVat*100)/100} &euro; <Trans>bez DPH</Trans>
                              </td>
                              <td>
                                  {Math.round(node.amount * node.price1Vat*100)/100} &euro; <Trans>s DPH</Trans><br/>
                                  {Math.round(node.amount * node.price1NoVat*100)/100} &euro; <Trans>bez DPH</Trans>
                              </td>
                          </tr>
                      ))
                  }
                  {(cart.size() === 0) ? "" :
                            <tr>
                                <td>{payment}</td>
                                <td>1 ks</td>
                                <td>
                                {Math.round(paymentSum*120)/100} &euro; <Trans>s DPH </Trans><br/>
                                {Math.round((paymentSum)*100)/100} &euro; <Trans>bez DPH</Trans>
                                </td>
                                <td>
                                {Math.round(paymentSum*120)/100} &euro; <Trans>s DPH</Trans><br/>
                                {Math.round((paymentSum)*100)/100} &euro; <Trans>bez DPH</Trans>
                                </td>
                            </tr>
                }
                {(cart.size() != 0 && (payment === "Kuriér - platba prevodom" || payment === "Kuriér - Barion kartou" || payment === "Kuriér - dobierka")) ?
                            <tr>
                                <td>Termobox balenie</td>
                                <td>1 ks</td>
                                <td>
                                4.80
                                &euro; <Trans>s DPH </Trans><br/>
                                4&euro; <Trans>bez DPH</Trans>
                                </td>
                                <td>
                                4.80 &euro; <Trans>s DPH</Trans><br/>
                                4 &euro; <Trans>bez DPH</Trans>
                                </td>
                            </tr>
                 :"" }
                  </tbody>
              </table>
              <div className="form-row">
              <div><p>
                    “Váha jednotlivých druhov produktov sa može v skutočnosti mierne líšiť od deklarovanej váhy. Po obdržaní vašej objednávky, vám zašleme potvrdenie objednávky na vami zadaný email s presným uvedením ceny vybraného tovaru spolu s informáciou o najbližšom možnom termíne doručenia. Cena v tomto potvrdení už bude platná a záväzná.”

Minimálna objednávka = 30,00 EUR s DPH</p></div>

                  <div className="c50p">

                      <br/>
                      <div><input type="checkbox" id="vop" name="vop" ref={register({required: true})}/> <Trans>Súhlasim so <Link to={"/"+locale+"/obchodne-podmienky"}>všeobecnými obchodnými podmienkami</Link></Trans></div>
                      {errors.vop && <div className="requirements checkbox"><Trans>Povinný súhlas s všeobecnými obchodnými podmienkami</Trans></div>}
                      <div><input type="checkbox" id="gdpr" name="gdpr" ref={register({required: true})}/> <Trans>Súhlasím so spracovaním <Link to={"/"+locale+"/gdpr"}>osobných údajov</Link></Trans></div>
                      {errors.gdpr && <div className="requirements checkbox"><Trans>Povinný súhlas so spracovaním osobných údajov</Trans></div>}
                      <br/>
                      <div><input type="checkbox" id="mailchimp" name="mailchimp" onClick={checkboxMailchimpClick} defaultChecked={false} ref={register}/> <Trans>Chcem dostávať novinky emailom.</Trans></div>
                  </div>
                  <div className="c50p">
                      <br/>
                      <Reaptcha sitekey={process.env.RECAPTCHA_API_KEY} onVerify={onVerify} />
                  </div>
                  <div className="cleaner">&nbsp;</div>
              </div>
              <div className="form-row">
              <Alert color="danger" >
              {adBlockDetected && <strong className='alert-recaptcha'>Ak máte zapnutý Adblocker, môže dojsť k problému s odoslaním overenia reCaptcha.</strong>}
                </Alert>
                  <button type="submit"><Trans>Objednať s povinnosťou platby</Trans></button>
              </div>
          </form>
          }
      </div>
                </div>
            </section>
        </div>
    </div>
  );
};



export default OrderForm;
